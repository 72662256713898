import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { CustomerRepositoryService } from '@cogent/client/shared/services/api/customer.service';
import { GroupedPolicyHistoryItemModel, PolicyHistoryItemModel } from '@cogent/shared/models/policies/policy-history-item.model';
import { ScrollAnimationService } from '@cogent/client/shared/services/common/scroll-animation.service';
import { ExpensesByTradeForCustomer } from '@cogent/shared/models/policies/expenses-by-trade-for-customer.model';
import { Router, RouterModule } from '@angular/router';
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { UserNotificationApiService } from '@cogent/client/shared/services/api/user-notification-api.service';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { ApiService } from '@cogent/client/api';
import { MaintenanceServiceApiService } from '@cogent/client/shared/services/api/maintenance-services-api.service';
import { LocalSettingsRepositoryService } from "@cogent/client/shared/services/local-settings.service";
import { MatDialog } from '@angular/material/dialog';
import { RenewNowDialogComponent } from '@cogent/client/shared/components/customer-portal/renew-now-dialog/renew-now-dialog.component';
import { Entity, InvoiceSummary } from '@upkeeplabs/models/cogent';
import { MissionService, ShellMenuItemModel } from '@cogent/client/shared/services/mission-service';
import { MaterialSharedModule } from '@cogent/client/shared/common/modules/material-shared/material-shared.module';
import { CommonModule } from '@angular/common';
import { DoughnutComponent } from '@cogent/client/shared/components/data-visualization/doughnut/doughnut.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LottiePlayerComponent } from '../../visuals/lottie-player/lottie-player.component';
declare var Chart: any;

class GroupedInvoices {
    holderId: string;
    customerName: string;
    invoices: InvoiceSummary[];

    static getGroupedInvoices(invoiceSummaries: InvoiceSummary[]): GroupedInvoices[] {
        const result: GroupedInvoices[] = [];

        for (const invoiceSummary of invoiceSummaries) {
            let groupedItem = result.find(i => i.holderId === invoiceSummary.holderId);
            if (!groupedItem) {
                groupedItem = new GroupedInvoices();
                groupedItem.holderId = invoiceSummary.holderId;
                groupedItem.customerName = invoiceSummary.customerName;
                groupedItem.invoices = [];
                result.push(groupedItem);
            }
            groupedItem.invoices.push(invoiceSummary);
        }
        return result;
    }

    get balanceDue(): number {
        if (!this.invoices || this.invoices.length === 0) {
            return 0;
        }

        return this.invoices.map(i => i.amountDue)
            .reduce((sum, numberToAdd) => sum + numberToAdd);
    }
}

@Component({
    templateUrl: './homeowners-home.component.html',
    styleUrls: ['./homeowners-home.component.css'],
    standalone: true,
    imports: [CommonModule, RouterModule, MaterialSharedModule, DoughnutComponent, MatIconModule, MatButtonModule, LottiePlayerComponent]
})
export class HomeownersHomeComponent implements OnInit {
    myPolicies: any[];
    myFilteredPolicies: any[];
    maintainedProperties: any[];
    workOrders: WorkOrderSummaryClient[];
    customer: Entity;
    historyItems: GroupedPolicyHistoryItemModel[];
    ungroupedHistoryItems: PolicyHistoryItemModel[];
    showMoreHistoryButton = true;
    loadingMoreHistory = false;
    unpaidInvoices: any[];
    groupedUnpaidInvoices: GroupedInvoices[];
    mobileIndex = 0;
    loadingMoreWorkOrders = false;
    showMoreWorkOrdersButton = true;
    openWorkOrderCount = 0;
    noUnpaidInvoices = false;
    noPoliciesDialog = false;
    expensesByTrade: ExpensesByTradeForCustomer[];
    mappedExpenses: any[];
    // isScrolled = false;
    noExpenses = false;
    loggedInUser: Entity;
    unreadNotificationCount = 0;
    maintenanceAvailable = false;
    upcommingJobs: any[];
    upcomingInvoices: any[]

    scrollPosition = 0;
    isLegacy = true;

    constructor(
        private missionService: MissionService,
        private scrollAnimation: ScrollAnimationService,
        private customerService: CustomerRepositoryService,
        private entityApi: EntityApiService,
        private dialog: MatDialog,
        private userNotificationApi: UserNotificationApiService,
        private ngZone: NgZone,
        private localStorage: LocalSettingsRepositoryService,
        private maintApi: MaintenanceServiceApiService,
        private router: Router
    ) {


    }

    async ngOnInit() {
        const menuItems: ShellMenuItemModel[] = [new ShellMenuItemModel('Overview', null, null, null, '#overview'),
        new ShellMenuItemModel('Covered Properties', null, null, null, '#properties'),
        new ShellMenuItemModel('Service Requests', null, null, null, '#service-requests'),
        new ShellMenuItemModel('Payments', null, null, null, '#payments'),
        new ShellMenuItemModel('Settings', null, null, '/settings'),
        new ShellMenuItemModel('Log Out', null, () => this.router.navigateByUrl('/auth/log-out')),
        ];

        this.missionService.showOverrideMenuItems(menuItems);


        this.maintenanceAvailable = true;//await this.maintApi.maintServicesAvailable();




        this.entityApi.getLoggedInUser().then(user => {
            this.loggedInUser = user;
            this.customerService.getPoliciesForLoggedInUser()
                .then(policies => {
                    this.myPolicies = policies;
                    this.myFilteredPolicies = [];
                    let canRenewPolicies = this.myPolicies.filter(i => i.canRenew);
                    canRenewPolicies = canRenewPolicies.filter(i => !this.myFilteredPolicies.find(j => j.policyNumber === i.policyNumber && j.id !== i.id && (j.status === 'Active' || j.status === 'Pending' || j.status === 'Suspended')));
                    if (canRenewPolicies.length && this.localStorage.getSetting(`${canRenewPolicies[0].id}-dismissed`, 'false') !== 'true') {
                        const ref = this.dialog.open(RenewNowDialogComponent, { data: canRenewPolicies[0] });
                        ref.afterClosed().subscribe(result => {
                            if (result) {
                                if (result === 'dimiss') {
                                    this.localStorage.setSetting(`${canRenewPolicies[0].id}-dismissed`, 'true');
                                } else {
                                    this.router.navigateByUrl(`/renew/${canRenewPolicies[0].id}`);
                                }
                            }
                        })
                    }



                    policies.forEach(policy => {
                        if (policy.status === 'Active' || policy.status === 'Suspended') {
                            this.myFilteredPolicies.push(policy);
                        } else if (policy.canceledDate) {
                            const dateToCheck = new Date(policy.canceledDate);
                            dateToCheck.setMonth(dateToCheck.getDate() + 3);
                            if (dateToCheck < new Date()) {
                                this.myFilteredPolicies.push(policy);
                            }
                        } else if (policy.status === 'Pending') {
                            let policyToCheck = policy;
                            let addPolicy = true;
                            while (policyToCheck) {
                                policyToCheck = policies.find(i => i.previousPolicyId === policyToCheck.id);
                                if (policyToCheck && (policyToCheck.status === 'Suspended' || policyToCheck.status === 'Active' || policyToCheck.status === 'Pending')) {
                                    addPolicy = false;
                                }
                            }
                            if (addPolicy) {
                                this.myFilteredPolicies.push(policy);
                            }
                        } else if (policy.status === 'Expired') {
                            const renewal = policies.filter(i => i.previousPolicyId === policy.id)[0];

                            if (!renewal) {
                                const dateToCheck = new Date();
                                dateToCheck.setMonth(dateToCheck.getMonth() - 12);
                                if (dateToCheck < policy.expirationDate) {
                                    this.myFilteredPolicies.push(policy);
                                }
                            }
                        }
                    });


                    this.customerService.getRecentWorkOrdersForLoggedInUser()
                        .then(workOrders => {
                            this.workOrders = workOrders;
                            for (const wo of this.workOrders) {
                                wo.lines = wo.lines?.filter(i => !i.cancelledDate);
                            }
                            if (this.workOrders.length < 3) {
                                this.showMoreWorkOrdersButton = false;
                            }
                        });

                    this.entityApi.getFullEntity(user.id)
                        .then(async customer => {
                            this.customer = customer;

                            const nextYear = new Date()
                            nextYear.setFullYear(nextYear.getFullYear() + 1)
                            this.customerService.getUnpaidInvoices(null, customer.id, nextYear).then(async invoices => {
                                if (customer.loginId) {
                                    const pmInvoices = await this.customerService.getUnpaidPropertyManagerInvoices(customer.loginId);
                                    invoices = invoices.concat(pmInvoices);
                                }
                                this.unpaidInvoices = invoices;
                                this.unpaidInvoices.forEach(inv => {
                                    if (inv.dueDate < new Date()) {
                                        inv.selected = true;
                                    }
                                });

                                this.unpaidInvoices = this.unpaidInvoices.filter(i => i.selected);
                                this.noUnpaidInvoices = this.unpaidInvoices.length === 0;

                                this.upcomingInvoices = invoices.sort((a, b) => a.dueDate < b.dueDate ? -1 : 1).filter(invoice => invoice.dueDate > new Date())

                                this.groupedUnpaidInvoices = GroupedInvoices.getGroupedInvoices(this.unpaidInvoices as any);
                            });
                        });

                    // this.customerService.getUnpaidInvoicesForLoggedInUser()
                    //     .then(unpaidInvoices => {
                    //         this.unpaidInvoices = unpaidInvoices;
                    //         this.noUnpaidInvoices = unpaidInvoices.length === 0;
                    //         this.groupedUnpaidInvoices = GroupedInvoices.getGroupedInvoices(unpaidInvoices as any);
                    //     });

                    this.customerService.getOpenWorkOrderCountForLoggedInUser().then(count => this.openWorkOrderCount = count);
                    this.noExpenses = false;
                    this.customerService.getExpensesByTradeForLogin(user.loginId).then(expenses => {
                        this.expensesByTrade = expenses;
                        const colors = [
                            '#9600FF', '#AEBAF8', 'blue', 'orange', 'purple', 'yellow'
                        ];

                        let metrics = expenses.map(i => ({ name: i.tradeName, value: i.totalExpenses * 1.25, color: colors[expenses.indexOf(i)] }));

                        if (metrics.length === 0 || metrics.map(i => i.value).reduce((a, b) => a + b) === 0) {
                            metrics = [{ name: 'HVAC', value: 643, color: colors[0] }, { name: 'Plumbing', value: 232, color: colors[1] }, { name: 'Appliance', value: 135, color: colors[2] }];
                            this.noExpenses = true;
                        }
                        this.mappedExpenses = metrics;

                        this.userNotificationApi.getUnreadNotificationCount().then(count => {
                            if (count > 0) {
                                setTimeout(() => {
                                    this.unreadNotificationCount = count;

                                }, 4000);
                            }
                        });
                    });
                });

            this.customerService.getUpcommingAppointments(user.loginId).then(upcomming => {
                this.upcommingJobs = upcomming;
                if (this.maintenanceAvailable) {
                    this.maintApi.getAppointmentsForLogin(user.loginId).then(maintAppointments => {

                        maintAppointments = maintAppointments.filter(i => !i.cancellationDate && i.scheduledDate > new Date());
                        const transformed = maintAppointments.map(i => {
                            return {
                                id: i.maintenanceServiceCustomerPropertyId,
                                scheduledDate: i.scheduledDate,
                                itemName: i.maintenanceServiceName,
                                propertyAddress: i.propertyAddress1,
                                type: 'Maintenance',
                                appointmentId: i.id,
                            };
                        });

                        this.upcommingJobs = this.upcommingJobs.concat(transformed).sort((a, b) => a.scheduledDate > b.scheduledDate ? 1 : -1);
                        if (this.upcommingJobs.length > 3) {
                            this.upcommingJobs = this.upcommingJobs.slice(0, 3);
                        }
                    });
                }
            });


            if (this.maintenanceAvailable) {
                this.maintApi.getMaintServiceSummaries(user.loginId).then(summaries => {
                    this.maintainedProperties = summaries.filter(i => i.orderedServices);
                });
            }
        });
    }

    get customerFirstName() {
        if (!this.loggedInUser || !this.loggedInUser.name) {
            return null;
        }

        if (this.loggedInUser.firstName) {
            return this.loggedInUser.firstName;
        }

        const split = this.loggedInUser.name.split(' ');
        return split[0];

    }

    get expensesLabel() {
        return this.noExpenses ? 'Potential Savings *' : 'Total Savings *';
    }

    getThumbnailMargin(line, workOrder: WorkOrderSummaryClient) {
        return (workOrder.lines.indexOf(line) * 25) + 'px';
    }

    get canSubmitServiceRequest() {
        return this.myFilteredPolicies && this.myFilteredPolicies.length > 0;
    }

    get totalDue() {
        return this.unpaidInvoices?.map(i => i.amountDue).reduce((a, b) => a + b, 0);
    }

    get requestServiceRoute() {
        const slash = ApiService.isLegacy ? '/' : '';
        if (!this.myFilteredPolicies) {
            return `${slash}select-property`;
        }
        const nonExpiredFilteredPolicies = this.myFilteredPolicies.filter(i => i.status !== 'Expired');
        if (nonExpiredFilteredPolicies.length === 1) {
            return `${slash}request-service/${nonExpiredFilteredPolicies[0].id}`;
        } else {
            return `${slash}select-property`;
        }
    }

    goToMore() {
        document.getElementById('properties').scrollIntoView();
    }

    get baseUrl() {
        return ApiService.endPointDotNet;
    }

    getThumbnailUrl(workOrderLine: any) {
        return `${ApiService.endPointDotNet}WorkOrderItem/${workOrderLine.itemId}/photo`;
    }



    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.ngZone.run(() => {
            this.scrollPosition = window.scrollY;
        });
    }

    loadMoreWorkOrders() {
        this.loadingMoreWorkOrders = true;

        const startDate = this.workOrders[this.workOrders.length - 1].createdDate;

        this.entityApi.getLoggedInUser().then(user => {
            this.customerService.getRecentWorkOrdersForLoggedInUser(startDate, 20)
                .then(workOrders => {
                    this.loadingMoreWorkOrders = false;
                    workOrders.forEach(wo => { this.workOrders.push(wo); });
                    if (workOrders.length < 20) {
                        this.showMoreWorkOrdersButton = false;
                    }
                });
        });
    }

    scrollToFragment(fragment: string) {
        const element = document.getElementById(fragment.replace('#', ''));
        element.scrollIntoView({ behavior: 'smooth' });
        // this.scrollAnimation.scrollToElement(fragment);
    }

    get balanceDue(): number {
        if (!this.unpaidInvoices || this.unpaidInvoices.length === 0) {
            return 0;
        }

        return this.unpaidInvoices.map(i => i.amountDue)
            .reduce((sum, numberToAdd) => sum + numberToAdd);
    }

    loadMoreHistory() {
        this.loadingMoreHistory = true;
        const startDate =
            this.ungroupedHistoryItems[this.ungroupedHistoryItems.length - 1]
                .statusDate;
        this.entityApi.getLoggedInUser().then(user => {
            this.customerService.getPolicyHistory(user.id, startDate, 20)
                .then(historyItems => {
                    if (historyItems.length < 20) {
                        this.showMoreHistoryButton = false;
                    }

                    historyItems.forEach(hi => { this.ungroupedHistoryItems.push(hi); });

                    this.historyItems = GroupedPolicyHistoryItemModel.fromHistoryItems(
                        this.ungroupedHistoryItems);
                    this.loadingMoreHistory = false;
                });
        });
    }

    get totalExpenses() {
        if (!this.expensesByTrade || this.expensesByTrade.length === 0) {
            return 0;
        }

        return this.expensesByTrade.map(i => i.retailTotalExpenses).reduce((a, b) => a + b);
    }
}
