<div class="wizard-header" [class.sub-component]="isSubComponent" *ngIf="selectedIndex !== totalSteps + 1">
    <div class="container">
        <div aflex>
            <div nogrow style="padding-top:5px">
                <app-circle-widget-small [percent]="pctComplete" [height]="45"></app-circle-widget-small>
            </div>
            <div style="margin-left: 0;">
                <div *ngIf="selectedIndex === 0 && !addMode">
                    <div class="container" aflex>
                        <div>
                            <h1 class="page-title fade-in-up">
                                <span class="big">Your</span><br>
                                <span class="little">Property</span>
                            </h1>


                        </div>
                        <div nogrow style="padding-top: 12px; padding-right: 10px;">
                            <button [disabled]="!isAddressStepComplete" class="next-button small-primary"
                                (click)="loadProductOfferings()" mat-button mat-raised-button color="primary">
                                <div>
                                    Next
                                    <mat-icon>chevron_right</mat-icon>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="(selectedIndex === 1 && !addMode) || (selectedIndex === 0 && addMode)">
                    <div class="container" aflex>
                        <div>
                            <h1 class="page-title fade-in-up">
                                <span class="big">Maintenance</span><br>
                                <span class="little">Products</span>
                            </h1>
                        </div>
                        <div nogrow style="padding-top: 12px; padding-right: 10px;">
                            <button mat-button (click)="maintenanceProductsGoBack()" *ngIf="!addMode">
                                <mat-icon>chevron_left</mat-icon>
                            </button>
                            <button mat-button [disabled]="!canConfigServices" [class.shake-animate]="canConfigServices"
                                class="next-button small-primary" (click)="configServices()" mat-raised-button
                                color="primary">
                                <div>
                                    Next
                                    <mat-icon>chevron_right</mat-icon>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedIndex >= servicesStartIndex && selectedIndex < servicesEndIndex">
                    <div class="container" aflex>
                        <div>
                            <h1 class="page-title fade-in-up">
                                <span class="big">Configure</span><br>
                                <span class="little">Services</span>
                            </h1>
                        </div>
                        <div nogrow style="padding-top: 12px; padding-right: 10px;">
                            <button mat-button (click)="this.selectedIndex = this.selectedIndex - 1">
                                <mat-icon>chevron_left</mat-icon>
                            </button>
                            <button mat-button mat-raised-button class="next-button small-primary"
                                [disabled]="!isServiceSelected" color="primary" (click)="configuredServiceNext()">
                                <div>
                                    Next
                                    <mat-icon>chevron_right</mat-icon>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div *ngIf="selectedIndex === servicesEndIndex && !addMode && !skipCustomer">
                    <div class="container" aflex>
                        <div>
                            <h1 class="page-title fade-in-up">
                                <span class="big">Contact</span><br>
                                <span class="little">Information</span>
                            </h1>
                        </div>
                        <div nogrow style="padding-top: 12px; padding-right: 10px;">
                            <button mat-button (click)="selectedIndex = selectedIndex - 1">
                                <mat-icon>chevron_left</mat-icon>
                            </button>
                            <button mat-button mat-raised-button class="next-button small-primary"
                                [disabled]="savingCustomer" color="primary" [disabled]="!contactFilledOut"
                                (click)="saveCustomerAndAdvance()">
                                <div>
                                    Next
                                    <mat-icon *ngIf="!savingCustomer">chevron_right</mat-icon>
                                    <mat-spinner *ngIf="savingCustomer" class="thirty"></mat-spinner>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="(selectedIndex === servicesEndIndex + 1 && !addMode && !skipCustomer) || (selectedIndex === servicesEndIndex && skipCustomer)">
                    <div class="container" aflex>
                        <div>
                            <h1 class="page-title fade-in-up">
                                <span class="big">Payment</span><br>
                                <span class="little">Information</span>
                            </h1>
                        </div>
                        <div nogrow style="padding-top: 12px; padding-right: 10px;">
                            <button mat-button (click)="paymentGoBack()">
                                <mat-icon>chevron_left</mat-icon>
                            </button>
                            <button mat-button mat-raised-button class="next-button small-primary" color="primary"
                                [disabled]="!selectedCard" (click)="selectedIndex = selectedIndex + 1">
                                <div>Next
                                    <mat-icon>chevron_right</mat-icon>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="(selectedIndex === servicesEndIndex + 2 && !addMode && !skipCustomer) || (selectedIndex === servicesEndIndex && addMode ) || (selectedIndex === servicesEndIndex + 1 && skipCustomer)">
                    <div class="container" aflex>
                        <div>
                            <h1 class="page-title fade-in-up">
                                <span class="big">Order</span><br>
                                <span class="little">Review</span>
                            </h1>
                        </div>
                        <div nogrow style="padding-top: 12px; padding-right: 10px;">
                            <button mat-button (click)="selectedIndex = selectedIndex - 1">
                                <mat-icon>chevron_left</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <mat-tab-group style="" class="hide-tabs tab-group" [class.cogent]="isCogent"
        (selectedIndexChange)="selectedIndexChange($event)" [(selectedIndex)]="selectedIndex">
        <mat-tab label="First" *ngIf="!addMode">


            <div *ngIf="orderFromPortal && !showNewPropertyEntry">
                <div class="header-container">
                    <div flex>
                        <div nogrow>
                            <img class="page-icon-image" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/house-frame.svg">
                        </div>
                        <div>
                            <p class="info-detail">Select Your Property</p>
                        </div>
                    </div>
                </div>
                <div class="plan-selection-outer-container">
                    <div class="plan-selection-container" style="scroll-snap-type: x proximity">
                      @if(authService.parsedJwt?.sub && !existingProperites) {
                        <div class="existing-property" style="display: inline-flex; align-items: center; justify-content: center">
                          <mat-spinner></mat-spinner>
                        </div>
                      } @else {
                        <button mat-button (click)="selectedProperty = prop" class="existing-property"
                                *ngFor="let prop of existingProperites">
                          <mat-icon class="checked" *ngIf="selectedProperty && selectedProperty.id === prop.id">check
                          </mat-icon>
                          <img bottommargin30 src="{{baseUrl}}address/{{prop.id}}/StreetView?width=150&height=150" />
                          <h2>{{prop.address1}}</h2>
                          <h3 *ngIf="prop.address2">{{prop.address2}}</h3>
                          <h3>{{prop.city}}, {{prop.state}} {{prop.postalCode}}</h3>
                        </button>
                      }
                      <button mat-button class="new-property-btn" (click)="showNewPropertyEntry = true">
                        <mat-icon>add</mat-icon><br>
                        New Property
                      </button>

                    </div>
                </div>
            </div>

            <div *ngIf="!orderFromPortal || showNewPropertyEntry" class="padding fade-in-up"
                style="max-width: 800px;width: 100%; display: table;margin: 0 auto">
                <div class="header-container">
                    <div flex>
                        <div nogrow>
                            <img class="page-icon-image" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/house-frame.svg">
                        </div>
                        <div>
                            <p class="info-detail">Please enter your property information</p>
                        </div>
                    </div>
                </div>
                <!-- <app-address-editor [(addressComplete)]="addressComplete" [address]="address"></app-address-editor> -->

                <div style="display: table;margin: 0 auto;width: 100%;max-width: 500px;" topmargin40>
                    <app-single-address-entry #addressEntry [loadPropertyMeta]="true"
                        (addressChange)="addressCompleteChange($event)"
                        [(address)]="address"></app-single-address-entry>
                </div>

                <button mat-button mat-raised-button *ngIf="orderFromPortal"
                    (click)="showNewPropertyEntry = false">Choose Existing</button>
            </div>
        </mat-tab>
        <mat-tab label="Second">
            <div class="center" *ngIf="loadingServices" topmargin40>
                <mat-spinner style="display: inline-block;"></mat-spinner>
            </div>
            <div *ngIf="!loadingServices">
                <div class="plan-selection-outer-container">
                    <div class="plan-selection-container">
                        <div *ngFor="let service of offerings" class="service-item plan">
                            <div class="flip-container" [class.flip-it]="service.flipped">
                                <div class="flipper">

                                    <div class="front" [style.background-image]="getBackground(service)">
                                        <div class="lottie-container">
                                            <lottie-player style="height: 200px"
                                                *ngIf="lottieReset && service.lottieAnimationUrl"
                                                [src]="service.lottieAnimationUrl" background="transparent" speed="1"
                                                intermission="5000" loop autoplay></lottie-player>
                                        </div>
                                        <mat-icon class="checked" *ngIf="service.selected">check</mat-icon>
                                        <div class="service-container">
                                            <div>
                                                <h2 class="category-name-h2" [style.color]="service.color">
                                                    {{service.maintenanceServiceName}}</h2>
                                                <div class="data-label" style="margin-top: 20px;">
                                                    Starting At</div>
                                                <h2 class="price">{{service.startingAt | specialCurrency}}</h2>
                                                <div *ngIf="!service.showMonthly">Per Visit</div>
                                                <div *ngIf="service.showMonthly">Per Month</div>

                                                <button large [style.background-color]="service.color" mat-button
                                                    mat-raised-button topmargin20 color="primary"
                                                    class="plan-detail-btn"
                                                    (click)="service.selected = !service.selected">
                                                    <mat-icon *ngIf="service.selected">check_circle_outline</mat-icon>

                                                    <span *ngIf="service.selected">
                                                        Added
                                                    </span>
                                                    <span *ngIf="!service.selected">
                                                        Select
                                                    </span>
                                                </button>
                                                <br>
                                                <button mat-button mat-raised-button *ngIf="!service.brochureUrl"
                                                    class="plan-detail-btn" (click)="service.flipped = true">Learn
                                                    More</button>

                                                <a mat-button mat-raised-button target="_blank"
                                                    *ngIf="service.brochureUrl" [href]="service.brochureUrl">
                                                    Learn More
                                                </a>

                                            </div>

                                            <div>
                                                <div class="summary" *ngIf="service.shortSummary">
                                                    {{service.shortSummary}}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="back">

                                        <button class="close-detail-button" (click)="service.flipped = false">
                                            <mat-icon>chevron_left</mat-icon><br> Back
                                        </button>

                                        <h2 [style.color]="service.color" [style.border-bottom-color]="service.color"
                                            style="border-bottom-style: solid;border-bottom-width: 1px;padding-bottom: 5px;margin-bottom: 15px">
                                            {{service.maintenanceServiceName}}</h2>
                                        <div style="text-align: left;" [innerHTML]="service.contractLanguage">

                                        </div>
                                        <button [style.background-color]="service.color" mat-button mat-raised-button
                                            topmargin20 color="primary" class="plan-detail-btn"
                                            (click)="service.selected = !service.selected;service.flipped = false">
                                            <mat-icon *ngIf="service.selected">clear</mat-icon>
                                            <span *ngIf="service.selected">
                                                Remove
                                            </span>
                                            <span *ngIf="!service.selected">
                                                Select
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="offerings && offerings.length === 0" class="center fade-in-up">
                    <svg style="height: 180px;" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 581.27 562.55">
                        <defs>
                            <style>
                                .cls-1111 {
                                    fill: #ef783f;
                                    transform: scale(2.7)
                                }

                                .cls-1 {
                                    fill: #333;
                                    opacity: .8;
                                }

                                .cls-2 {
                                    fill: none;
                                    stroke: #333;
                                    stroke-linecap: round;
                                    stroke-miterlimit: 10;
                                    stroke-width: 22px;
                                }

                                @keyframes animate-mouth {
                                    from {
                                        d: path('M119.31,238.13c23.22-1.06,51.9-1.68,82.93-1.68,27.36,0,52.88,.49,74.44,1.33');
                                    }

                                    to {
                                        d: path('M119.31,261.13c23.22-19.79,51.9-31.49,82.93-31.49,27.36,0,52.88,9.08,74.44,24.79')
                                    }
                                }


                                #mouth {
                                    animation: animate-mouth 5s ease;
                                    animation-delay: 1s;
                                    animation-fill-mode: forwards;
                                    opacity: .8;
                                }
                            </style>
                        </defs>
                        <path class="cls-1111"
                            d="M200.2,142.86c-6.95,15.47-18.91,23.78-32.95,32.77-18.46,11.83-38.19,20.24-59.66,24.65-19.84,4.08-37.83,1.01-52.79-13.61-6.42-6.28-13.08-12.41-18.81-19.29-13.17-15.81-24.49-32.84-32.23-52.08C.01,105.98-1.55,96.24,1.97,87c3.15-8.27,7.37-16.76,13.23-23.22,13.4-14.76,28.12-28.32,42.27-42.4,7.88-7.84,17.13-13.66,27.41-17.57,10.71-4.08,21.32-5.27,33.06-1.67,24.21,7.42,44.84,20.62,64.21,36.21,5.41,4.35,10.15,9.58,14.9,14.69,15.49,16.68,16.71,36.85,12.88,57.69-1.88,10.22-5.7,20.08-8.64,30.1-.4-.13-.69,2.15-1.09,2.02h0Z" />
                        <path class="cls-1"
                            d="M198,34c43.81,0,84.99,17.06,115.97,48.03,30.98,30.98,48.03,72.16,48.03,115.97s-17.06,84.99-48.03,115.97c-30.98,30.98-72.16,48.03-115.97,48.03s-84.99-17.06-115.97-48.03c-30.98-30.98-48.03-72.16-48.03-115.97s17.06-84.99,48.03-115.97c30.98-30.98,72.16-48.03,115.97-48.03m0-34C88.65,0,0,88.65,0,198s88.65,198,198,198,198-88.65,198-198S307.35,0,198,0h0Z" />
                        <rect class="cls-1" x="340.96" y="324.25" width="26" height="66"
                            transform="translate(-148.16 385.4) rotate(-48.47)" />
                        <rect class="cls-1" x="438.1" y="336.61" width="69" height="250.05" rx="18.95" ry="18.95"
                            transform="translate(-186.32 509.41) rotate(-48.47)" />
                        <g id="face">
                            <g>
                                <circle class="cls-1" cx="147.7" cy="167.79" r="23.79" />
                                <circle class="cls-1" cx="248.3" cy="167.79" r="23.79" />
                            </g>
                            <path id="mouth" class="cls-2"
                                d="M119.31,238.13c23.22-1.06,51.9-1.68,82.93-1.68,27.36,0,52.88,.49,74.44,1.33" />
                        </g>
                    </svg>
                    <h1 topmargin40 bottommargin40>Sorry</h1>
                    <p class="info">We couldn't find any available services for your property address. <br><br>
                        But check back later. We're adding new providers all the time.
                    </p>

                </div>
            </div>
            @if (router.url.includes('sales') && !loadingServices) {
              <form class="send-quote-form">
                <h2>Email HMS Quote</h2>
                <mat-form-field appearance="outline">
                  <mat-label>Name *</mat-label>
                  <input matInput name="q-name" id="q-name" [(ngModel)]="quoteName">
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Phone *</mat-label>
                  <input matInput name="q-phone" type="tel" mask="(000) 000-0000" id="q-phone" [(ngModel)]="quotePhone">
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Email *</mat-label>
                  <input matInput name="q-email" type="email" id="q-email" [(ngModel)]="quoteEmail">
                </mat-form-field>

                <button [disabled]="canSendQuote || sendingQuote || quoteSent" (click)="sendWebQuoteFromCogent()" mat-raised-button mat-button>
                  <mat-spinner *ngIf="sendingQuote" class="thirty"></mat-spinner> Send Quote
                </button>
              </form>
            }
        </mat-tab>
        <mat-tab label="Third" *ngFor="let selectedOffering of selectedOfferings">

            <div class="offering-grouping-container">
                <!-- <div class="top-circle" [style.background-image]="getBackground(selectedOffering)"></div> -->
                <div aflex>
                    <div nogrow>
                        <div class="bg-circle" *ngIf="!selectedOffering.lottieAnimationUrl"
                            [style.background-image]="getBackground(selectedOffering)"></div>
                        <div class="bg-circle" *ngIf="selectedOffering.lottieAnimationUrl">
                            <lottie-player style="height: 150px" *ngIf="lottieReset"
                                [src]="selectedOffering.lottieAnimationUrl" background="transparent" speed="1"
                                intermission="25000" loop autoplay></lottie-player>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h1 [style.color]="selectedOffering.color">{{selectedOffering.maintenanceServiceName}}
                                <button mat-button (click)="showOfferingContractDetails(selectedOffering)">
                                    <mat-icon>help</mat-icon>
                                </button>
                            </h1>
                            <ol class="step-list">
                                <li *ngFor="let filter of selectedOffering.filtersToShow">
                                    <div bottommargin20>
                                        <div class="step-label" bottommargin20>{{filter.name}}

                                        </div>

                                        <button (click)="setFilterOption(selectedOffering, filter,option)"
                                            [class.selected]="filter.selectedOption === option"
                                            *ngFor="let option of filter.filterOptions" mat-button
                                            class="filter-option toggle-button">
                                            <div class="filter-option-pic-container">
                                                <img [class.invert]="option.invertOnSelection"
                                                    [src]="option.pictureUrl"><br>
                                            </div>
                                            {{option.name}}
                                        </button>
                                    </div>
                                </li>
                                <ng-container
                                    *ngIf="selectedOffering.maintenanceServiceId === 'b688383e-0dcf-4943-bbd1-7c53477345b9'">
                                    <!-- House Cleaning -->
                                    <!-- <li>
                                        <div class="step-label">
                                            Verify your home's square footage
                                        </div>
                                        <div *ngIf="editSquareFootage" aflex>
                                            <div nogrow>
                                                <mat-form-field style="max-width: 200px;" topmargin20
                                                    appearance="outline">
                                                    <mat-label>Home SQFT</mat-label>
                                                    <input matInput [(ngModel)]="address.squareFeet"
                                                        (ngModelChange)="lotChange($event)">

                                                </mat-form-field>
                                                <mat-slider [discrete]="true" aria-label="SQFT" [step]="100" [min]="500"
                                                    [max]="10000">
                                                    <input matSliderThumb name="sqft-slider"
                                                        [(ngModel)]="address.squareFeet" #slider
                                                        (ngModelChange)="lotChange($event)">
                                                </mat-slider>
                                            </div>
                                            <div>
                                                <button topmargin20 mat-button (click)="editSquareFootage = false">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div *ngIf="!editSquareFootage">
                                            <div aflex>
                                                <div nogrow>
                                                    <h2 class="price">{{address.squareFeet}}</h2>
                                                </div>
                                                <div>
                                                    <button class="edit-icon" mat-button mat-raised-button
                                                        (click)="editSquareFootage = true">
                                                        <mat-icon>edit</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="data-label" style="margin-top: -10px">SQFT</div>
                                        </div>
                                    </li> -->

                                    <li>
                                        <div class="step-label">
                                            Verify Your Bedroom Count
                                        </div>
                                        <div topmargin20>

                                            <button style="margin-left: 10px; margin-bottom: 10px" mat-button
                                                mat-raised-button
                                                (click)="address.bedroomCount = i;setFilters();showOtherBedroom=false"
                                                class="toggle-button" [class.selected]="i === address.bedroomCount"
                                                *ngFor="let i of bedroomCounts">
                                                {{i}}
                                            </button>
                                            <button style="margin-left: 10px;margin-bottom: 10px" mat-button
                                                mat-raised-button class="toggle-button"
                                                [class.selected]="showOtherBedroom" (click)="showOtherBedroom = true">
                                                Other
                                            </button>
                                        </div>
                                        <div *ngIf="showOtherBedroom">
                                            <mat-form-field style="max-width: 200px;" topmargin20 appearance="outline">
                                                <mat-label>Bedroom Count</mat-label>
                                                <input matInput type="number" [(ngModel)]="address.bedroomCount"
                                                    (ngModelChange)="setFilters()">
                                            </mat-form-field>


                                        </div>

                                    </li>

                                    <li>
                                        <div class="step-label">
                                            Verify Your Bathroom Count
                                        </div>
                                        <div topmargin20>
                                            <button style="margin-left: 10px; margin-bottom: 10px" mat-button
                                                mat-raised-button
                                                (click)="address.bathroomCount = i;setFilters();showOtherBathroom=false"
                                                class="toggle-button" [class.selected]="i === address.bathroomCount"
                                                *ngFor="let i of bathroomCounts">
                                                {{i}}
                                            </button>
                                            <button style="margin-left: 10px;margin-bottom: 10px" mat-button
                                                mat-raised-button class="toggle-button"
                                                [class.selected]="showOtherBathroom" (click)="showOtherBathroom = true">
                                                Other
                                            </button>

                                            <div *ngIf="showOtherBathroom">
                                                <mat-form-field style="max-width: 200px;" topmargin20
                                                    appearance="outline">
                                                    <mat-label>Bathroom Count</mat-label>
                                                    <input matInput type="number" (blur)="updateBathroomCount()"
                                                        [(ngModel)]="address.bathroomCount"
                                                        (ngModelChange)="setFilters()">
                                                </mat-form-field>

                                            </div>

                                        </div>

                                    </li>
                                </ng-container>
                                <ng-container
                                    *ngIf="selectedOffering.maintenanceServiceId === 'f401dcec-0547-45c4-af6e-8bc792bf1e64'">
                                    <li *ngIf="selectedOffering.readyToShowOfferings">
                                        <div class="step-label">
                                            Verify your lawn size
                                        </div>
                                        <div id="map-edit-container" *ngIf="editSquareFootage">
                                            <div aflex style="height: 0">
                                                <div nogrow>
                                                    <button mat-button (click)="editSquareFootage = false"
                                                        class="close-map-edit-button">
                                                        <mat-icon>close</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div style="height: 100%; overflow-y: auto">
                                                <app-map-measure [propertyAddress]="address"
                                                    (totalAreaChange)="updateSQFT($event)"></app-map-measure>
                                            </div>
                                        </div>
                                        <div *ngIf="true" style="height: 100%; overflow-y: auto">

                                            <div aflex>
                                                <div nogrow>
                                                    <h2 class="price">{{address.lawnSquareFootage | number: '1.0-0'
                                                        }}<span *ngIf="!lawnEdited">*</span>
                                                        <span class="data-label">SQFT</span>

                                                    </h2>

                                                </div>

                                                <div>
                                                    <button class="edit-icon" mat-button mat-raised-button
                                                        (click)="editSquareFootage = true">
                                                        <mat-icon style="margin-right: 0;">edit</mat-icon>
                                                    </button>
                                                </div>
                                            </div>

                                            Acres: {{lotAcres | number: '1.0-2'}}
                                            <div *ngIf="!lawnEdited" style="padding-right: 20px">*This was our best
                                                guess based on public records. You can draw out your lawn using our <a
                                                    style="cursor: pointer;text-decoration: underline;color: blue"
                                                    (click)="editSquareFootage = true">Edit</a> tool.</div>

                                        </div>
                                    </li>

                                </ng-container>

                                <ng-container
                                    *ngIf="selectedOffering.maintenanceServiceId === 'dea4bb75-8786-471d-b260-96f721de3659'">



                                </ng-container>

                                <ng-container
                                    *ngIf="selectedOffering.maintenanceServiceId === '4c565a6f-a670-435a-96e1-c4d70f5b940f'">

                                    <li>
                                        <div class="step-label">
                                            Verify your home's square footage
                                        </div>
                                        <div *ngIf="editSquareFootage" aflex>
                                            <div nogrow>
                                                <mat-form-field style="max-width: 200px;" topmargin20
                                                    appearance="outline">
                                                    <mat-label>Home SQFT</mat-label>
                                                    <input matInput [(ngModel)]="address.squareFeet"
                                                        (ngModelChange)="lotChange($event)">
                                                </mat-form-field>
                                                <mat-slider>
                                                    <input matSliderThumb name="sqft-slider"
                                                        [(ngModel)]="address.squareFeet" #slider
                                                        (ngModelChange)="lotChange($event)">
                                                </mat-slider>

                                            </div>
                                            <div>
                                                <button topmargin20 mat-button (click)="editSquareFootage = false">
                                                    <mat-icon>close</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div *ngIf="!editSquareFootage">
                                            <div aflex>
                                                <div nogrow>
                                                    <h2 class="price">{{address.squareFeet}}</h2>
                                                </div>
                                                <div>
                                                    <button class="edit-icon" mat-button mat-raised-button
                                                        (click)="editSquareFootage = true">
                                                        <mat-icon>edit</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="data-label" style="margin-top: -10px">SQFT</div>
                                        </div>
                                    </li>
                                </ng-container>
                                <ng-container
                                    *ngIf="selectedOffering.maintenanceServiceId === 'aa37213c-b515-4255-a8e6-1679eaa1a4fe'">
                                    <li>
                                        <div class="step-label">
                                            How many windows do you have?
                                        </div>
                                        <div topmargin20>
                                            <div class="window-selector" style="min-height: 247px;">
                                                <h2 bottommargin20>Normal Window</h2>
                                                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/one-panel.svg">
                                                <br>
                                                <button mat-button (click)="incrementOneUp(selectedOffering)">
                                                    <mat-icon>add</mat-icon>

                                                </button>
                                                <button mat-button (click)="incrementOneDown(selectedOffering)">
                                                    <mat-icon>remove</mat-icon>
                                                </button>

                                                <h1 *ngIf="onePanelCount > 0" class="pane-count">

                                                    {{onePanelCount}}
                                                </h1>

                                            </div>
                                            <div class="window-selector">
                                                <h2>French Pane

                                                    <button (click)="showFrenchPanes()" mat-button
                                                        title="What are french pane windows">
                                                        <mat-icon>help</mat-icon>
                                                    </button>
                                                </h2>
                                                <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/six-panel.svg">
                                                <br>
                                                <button mat-button (click)="incrementSixUp(selectedOffering)">
                                                    <mat-icon>add</mat-icon>

                                                </button>
                                                <button mat-button (click)="incrementSixDown(selectedOffering)">
                                                    <mat-icon>remove</mat-icon>
                                                </button>

                                                <h1 *ngIf="sixPanelCount > 0" class="pane-count">

                                                    {{sixPanelCount}}
                                                </h1>
                                            </div>
                                        </div>
                                    </li>
                                </ng-container>

                                <li topmargin30
                                    *ngIf="selectedOffering.readyToShowOfferings && selectedOffering?.filteredOfferings?.length">
                                    <div class="step-label">
                                        Choose your frequency of visits
                                    </div>
                                    <ng-container *ngIf="selectedOffering.readyToShowOfferings">
                                        <button mat-button class="toggle-button frequency-button"
                                            [class.selected]="selectedOffering.selectedOffering === offering"
                                            [disabled]="offering.loading" mat-raised-button
                                            (click)="selectOffering(selectedOffering, offering)"
                                            *ngFor="let offering of selectedOffering.filteredOfferings">
                                            {{offering.frequencyType}}

                                            <h2 class="price">{{offering.priceToShow | specialCurrency}}
                                                <span *ngIf="offering.initialVisitPrice">*</span>
                                            </h2>
                                            <div *ngIf="!offering.showMonthly">Per Visit<span *ngIf="offering.cancellationFee">
                                                *</span></div>
                                            <div *ngIf="offering.showMonthly">Per Month<span *ngIf="offering.cancellationFee">
                                                *</span></div>

                                            <mat-spinner *ngIf="offering.loading" class="thirty"></mat-spinner>
                                        </button>
                                    </ng-container>
                                </li>
                            </ol>

                            <div *ngIf="selectedOffering && selectedOffering.readyToShowOfferings && selectedOffering.filteredOfferings.length > 0
                                && selectedOffering.filteredOfferings[0].cancellationFee">

                                * Price for a commitment period of {{selectedOffering.filteredOfferings[0].cancellationFeeMonthsInEffect}} months from the date of your first appointment.
                                There will be a
                                {{selectedOffering.filteredOfferings[0].cancellationFee | currency}} cancellation fee if cancelled before the commitment period has elapsed.
                            </div>
                            <div class="center"
                                *ngIf="selectedOffering?.readyToShowOfferings && selectedOffering.filteredOfferings.length === 0">
                                <lottie-player style="height: 200px"
                                    src="https://lottie.host/9d8d9269-0a00-4bd2-9065-4fe0ad004809/FDlgAIJoMF.json"
                                    background="transparent" speed="1" intermission="5000" loop
                                    autoplay></lottie-player>

                                <p class="info-2">
                                    Sorry!<br> we couldn't find any services that match your property
                                </p>
                            </div>
                            <p class="info" *ngIf="selectedOffering.priceDisclaimer">
                                {{selectedOffering.priceDisclaimer}}</p>

                            <p class="info"
                                *ngIf="selectedOffering.selectedOffering && selectedOffering.selectedOffering.initialVisitPrice">
                                * The price for the initial visit will be
                                {{selectedOffering.selectedOffering.initialVisitPrice | specialCurrency}}.
                                Each month thereafter will be billed at the normal rate of
                                {{selectedOffering.selectedOffering.monthlyPrice | specialCurrency}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
        <mat-tab *ngIf="!addMode && !skipCustomer">
            <div class="header-container">
                <div flex>
                    <div nogrow>
                        <img class="page-icon-image" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/contact-card.svg">
                    </div>
                    <div>
                        <p class="info-detail">Please enter your contact information</p>
                    </div>
                </div>
            </div>
            <div class="padding">
                <div style="max-width: 800px; width: 100%; display: table;margin: 0 auto">
                    <app-basic-entity-entry [(entity)]="customer" nameLabel="Your Name" [nameIsSearchBox]="false"
                        [hidePaperless]="true" [hideTimezoneEntry]="true" [hideContactMethodsEntry]="true"
                        [showAddressToggle]="true" [hideWebsiteEntry]="true" [showRequiredName]="true"
                        [showRequiredEmail]="true" [overrideAddress]="false" [showSinglePhoneNumberEntry]="true"
                        [homePhoneRequired]="true" [defaultAddress]="address" defaultAddressLabel="Use Property Address"
                        [(canSave)]="entityCanSave">
                    </app-basic-entity-entry>

                    <h2 topmargin30 bottommargin20>Create Login</h2>

                    <mat-form-field appearance="outline" style="max-width: 300px">
                        <mat-label>
                            <mat-icon>lock_open</mat-icon>
                            Password *
                        </mat-label>
                        <input matInput type="password" (ngModelChange)="validatePassword()" [(ngModel)]="password">
                        <mat-hint *ngIf="!passwordsMatch">Password and confirmation do not match</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="outline" topmargin10 style="max-width: 300px">
                        <mat-label>
                            <mat-icon>lock_open</mat-icon>Confirm Password *
                        </mat-label>
                        <input matInput type="password" (ngModelChange)="validatePassword()"
                            [(ngModel)]="confirmPassword">
                        <mat-hint *ngIf="!passwordsMatch">Password and confirmation do not match</mat-hint>

                    </mat-form-field>
                </div>
            </div>
        </mat-tab>

        <mat-tab *ngIf="!addMode">
            <div class="header-container">
                <div flex>
                    <div nogrow>
                        <img class="page-icon-image" src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/two-credit-cards.svg">
                    </div>
                    <div>
                        <p class="info-detail">Please enter your payment information</p>
                    </div>
                </div>
            </div>


            <div class="padding">
                <app-payment-method-entry
                    *ngIf="(selectedIndex === servicesEndIndex + 1 && !skipCustomer) || (selectedIndex === servicesEndIndex && skipCustomer)"
                    [customerId]="customer.id" paymentDescription="Maintenance Services" [paymentAmount]="monthlyTotal"
                    [newCustomerMode]="!orderFromPortal && !cardSelected && !skipCustomer"
                    [(selectedCard)]="selectedCard" (selectedCardChange)="paymentValid($event)">
                </app-payment-method-entry>

                <div *ngIf="creditBalance" class="info" style="max-width: 400px;">
                    Good news, you have a credit balance of {{creditBalance | currency}} available. It will be applied
                    to your first invoice.
                </div>


                <div topmargin30 style="display: inline-block;">
                    <div>
                        <div aflex>
                            <div>
                                <app-display-total [hideTriangle]="true" [total]="monthlyTotal"
                                    label="Price Per Month"></app-display-total>
                            </div>
                            <div *ngIf="monthlyTotal !== firstMonthTotal">
                                <app-display-total [hideTriangle]="true" [total]="firstMonthTotal"
                                    label="Due Today"></app-display-total>
                            </div>
                            <div style="border-left: 1px dotted #777;padding-left: 20px;">
                                <div class="data-label" topmargin40>First Payment</div>
                                <h2 class="big-data" topmargin20>{{firstPayment | date: 'shortDate'}}</h2>
                            </div>

                        </div>
                    </div>
                    <!-- <h1>{{firstMonthTotal}}</h1> -->
                    <p style="max-width: 500px;" class="info"
                        *ngIf="monthlyTotal !== firstMonthTotal && !adjustmentItems">
                        Your first charge will be {{firstMonthTotal | currency}} to cover your initial visit. Thereafter
                        you will be charged at a rate of {{monthlyTotal | currency}} per month.
                    </p>
                    <p style="max-width: 500px;" class="info"
                        *ngIf="monthlyTotal !== firstMonthTotal && adjustmentItems && adjustmentItems.length > 0 && adjustmentItems[0].numberOfMonthsValid">
                        Your monthly charge will be {{firstMonthTotal | currency}} for the first
                        {{adjustmentItems[0].numberOfMonthsValid}} months. Thereafter
                        you will be charged at a rate of {{monthlyTotal | currency}} per month.
                    </p>


                    <div class="center">
                        <button mat-button (click)="showDetails = !showDetails">
                            <mat-icon class="rotate" [class.rotated]="showDetails">chevron_right</mat-icon>
                            <span *ngIf="!showDetails">
                                Show Details
                            </span>
                            <span *ngIf="showDetails">
                                Hide Details
                            </span>
                        </button>
                    </div>
                </div>

                <div class="scrollable-container">
                    <table class="table fade-in-up" *ngIf="showDetails">
                        <thead>
                            <tr>
                                <th>Service</th>
                                <th>Frequency Type</th>
                                <th class="right">Visits / Year</th>
                                <th class="right">Price/Visit</th>
                                <th class="right">Month Price</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let schedule of offeringSchedule">
                                <td>{{schedule.maintenanceServiceName}}</td>
                                <td>
                                    <span *ngIf="!schedule.editMode">{{schedule.frequencyType}}</span>
                                    <span *ngIf="schedule.editMode">

                                        <mat-form-field appearance="outline">
                                            <mat-select [(ngModel)]="schedule.offering"
                                                (ngModelChange)="updateOffering(schedule, $event)">
                                                <mat-option [value]="offering"
                                                    *ngFor="let offering of schedule.group.filteredOfferings">
                                                    {{offering.frequencyType}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </span>
                                </td>
                                <td class="right">{{schedule.visitsPerYear}}</td>
                                <td class="right">{{schedule.calculatedPrice | currency}}</td>
                                <td class="right">{{schedule.monthlyPrice | currency}}</td>
                                <td>
                                    <button mat-button class="toggle-button" [class.selected]="schedule.editMode"
                                        (click)="editSchedule(schedule)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button mat-button (click)="deleteItem(schedule)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <th colspan="4"></th>
                                <th class="right">
                                    {{monthlyTotal | currency}}
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <form topmargin40>
                    <mat-form-field appearance="outline" style="max-width: 400px">
                        <mat-label>Promotion Code</mat-label>
                        <input matInput name="promotionCode" [(ngModel)]="promotionCodeCode">
                        <button mat-button mat-raised-button style="margin-right: 5px;" matSuffix
                            [disabled]="!canLoadPromo" (click)="loadPromo()">
                            <mat-spinner *ngIf="loadingPromotionCode" class="thirty"></mat-spinner>
                            Load</button>
                    </mat-form-field>
                </form>

                <div class="step-label">What is the soonest you want service to begin?*</div>

                <div topmargin20>
                    <div style="max-width: 175px">
                        <app-date-picker placeholder="Start Date" [(date)]="startDate"
                            [min]="minStartDate"></app-date-picker>
                    </div>
                </div>
                <p class="italic">
                    * We'll send this date to our service pro. They'll check their schedule once
                    they have a firm appointment, we'll let you know the details.
                    @if(startDateDelayed) {
                    <br>
                    Your first payment will come out on {{firstPayment | date: 'shortDate'}}.
                    }
                </p>

            </div>
        </mat-tab>
        <mat-tab>
            <div class="padding">
                <div class="summary-container">
                    <div class="summary-item" *ngIf="!addMode">
                        <div class="icon-container">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/house-and-clouds-orange-blob.svg">
                        </div>
                        <h2>Property</h2>
                        <app-address-street-view [height]="100" [width]="100"
                            [address]="address"></app-address-street-view>
                        <div>{{address.address1}}</div>
                        <div>{{address.address2}}</div>
                        <div>{{address.city}}, {{address.state}} {{address.postalCode}}</div>

                        <div class="edit-container">
                            <button mat-button mat-raised-button (click)="selectedIndex = 0">
                                <mat-icon>edit</mat-icon> Change
                            </button>
                        </div>
                    </div>

                    <div class="summary-item right-side">
                        <div class="icon-container">
                            <img src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/description-orange-blog.svg">
                        </div>
                        <h2>Services</h2>

                        <div *ngFor="let schedule of offeringSchedule">
                            {{schedule.maintenanceServiceName}} ({{schedule.frequencyType}})
                        </div>

                        {{monthlyTotal | currency}}/month


                        <div class="edit-container">
                            <button mat-button mat-raised-button (click)="selectedIndex = 1">
                                <mat-icon>edit</mat-icon> Change
                            </button>
                        </div>
                    </div>

                    <div class="summary-item bottom-item" *ngIf="!addMode">

                        <div class="icon-container">
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/contact-page-orange-blob.svg">
                        </div>
                        <h2>Customer Information</h2>
                        {{customer.name}}<br>
                        {{customer.email}}<br>
                        {{customer.homeNumber.number | formatPhoneNumber}}
                        <div class="edit-container">
                            <button mat-button *ngIf="!skipCustomer" mat-raised-button
                                (click)="selectedIndex = servicesEndIndex">
                                <mat-icon>edit</mat-icon> Change
                            </button>
                        </div>
                    </div>

                    <div class="summary-item  right-side bottom-item" *ngIf="!addMode">

                        <div class="icon-container">
                            <img
                                src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/credit-card-orange-blob.svg">
                        </div>
                        <h2>Payment Information</h2>
                        <div *ngIf="selectedCard">
                            <img [src]="cardBrandUrl" style="height: 40px;"><br>
                            xxxx{{selectedCard.last4}}
                        </div>

                        <div class="edit-container">
                            <button mat-button mat-raised-button (click)="editPayment()">
                                <mat-icon>edit</mat-icon> Change
                            </button>
                        </div>
                    </div>

                  @if(!utmSource && this.marketingSources?.length > 0){
                    <div class="summary-item bottom-middle-item" >
                      <div class="icon-container">
                        <img
                          src="https://elevateh.blob.core.windows.net/cdn/images/customer-portal/v2022.10/person-orange-blob.svg">
                      </div>
                      <h2>How did you hear about us?</h2>
                      @if(this.marketingSources?.length >= 4) {
                        <mat-form-field appearance="outline">
                          <mat-label>Source</mat-label>
                          <mat-select [(ngModel)]="selectedMarketingSource" name="source">
                            @for (source of marketingSources; track source) {
                              <mat-option [value]="source">{{source.name}}</mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                      } @else {
                        <mat-radio-group [(ngModel)]="selectedMarketingSource">
                          @for (source of marketingSources; track source) {
                            <mat-radio-button [value]="source" >{{source.name}}</mat-radio-button>
                          }
                        </mat-radio-group>
                      }
                    </div>
                  }

                </div>


                <div *ngFor="let service of selectedOfferings" bottommargin30>
                    <div *ngIf="service.selectedOffering">
                        <h2>{{service.maintenanceServiceName}} </h2>
                        <div [innerHTML]="service.contractLanguage" class="contract-language"> </div>

                        <mat-checkbox [(ngModel)]="service.selectedOffering.approved">I Accept</mat-checkbox>
                    </div>
                </div>
                <div class="center">
                    <button mat-button mat-raised-button large class="no-modify" color="primary" (click)="save()"
                        [disabled]="!canSave">
                        <mat-spinner *ngIf="saving" class="thirty"></mat-spinner>
                        Order Now
                    </button>
                </div>
            </div>

        </mat-tab>
        <mat-tab>
            <div class="finish">
                <h1 bottommargin40>We are getting to work...</h1>
                <div class="maint-service-banner-container">
                    <!-- <app-maint-service-banner [doWindowCleaning]="true" [doLawnMower]="true" [doPool]="true" [doVacuum]="true">
                    </app-maint-service-banner> -->
                </div>
                <div class="fade-in-up" *ngIf="showLastSceneLastText">
                    <h1 class=" fade-in-up" topmargin40>So that you don't have to</h1>
                    <div topmargin40>
                        <a mat-button mat-raised-button color="primary" routerLink="/">
                            Go To My Portal
                        </a>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
