import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { WebChatApiService } from '@cogent/client/shared/services/api/web-chat-api.service';
import { CustomFunction, Entity } from '@upkeeplabs/models/cogent';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { MissionService, ExecuteFunctionArgs } from '@cogent/client/shared/services/mission-service';
import { ChatConfig } from '@cogent/shared/models/communication/chat-config.model';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { AmazonContactApiService } from '@cogent/client/shared/services/api/amazon-contact-api.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
declare var connect: any;


@Component({
    selector: 'app-connect-chat',
    templateUrl: './connect-chat.component.html',
    styleUrls: ['./connect-chat.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,
    ]
})
export class ConnectChatComponent implements OnInit {
    showChat = false;
    connecting = false;
    hideChatButton = false;
    chatSupported = false;
    chatConfig: ChatConfig;
    collapsed = false;
    adjustLocation = false;
    chatFunction: CustomFunction;

    constructor(private entityApi: EntityApiService,
        private zone: NgZone,
        private router: Router,
        private amazonContactApi: AmazonContactApiService,
        private chat: WebChatApiService,
        private activatedRoute: ActivatedRoute,
        private missionService: MissionService) { }

    ngOnInit(): void {
        this.chat.getWebChatInitialContact().then(chatFunc => {
            this.chatFunction = chatFunc;
        });

        this.entityApi.getSupportsChat().then(supported => {
            this.chatSupported = supported;
            if (supported) {
                setTimeout(() => {
                    connect.ChatInterface.init({
                        containerId: 'chat-client', // This is the id of the container where you want the widget to reside                    
                        headerConfig: {
                            isHTML: true,
                            render: () => {
                                return (`
                                <div class="chat-header-wrapper">
                                    <img src="assets/images/agent-profile.jpg">
                                    <h2 class="welcome-text">Chat With Us</h2>
                                </div>
                            `);
                            }
                        }
                    });
                });

            }
        });

        this.missionService.subscription.subscribe(message => {
            if (message.type === 'START-WEB-CHAT') {
                this.startChat(message.messageBody.id);
            }
        });

        this.router.events.subscribe(val => {

            if (val instanceof NavigationEnd) {
                const nav = val as NavigationEnd;
                if (nav.url.startsWith('/request-service/') || nav.url.startsWith('/quote') || nav.url.startsWith('/test') || nav.url.startsWith('/security/register') || nav.url.startsWith('/job-detail/')) {
                    this.adjustLocation = true;
                } else {
                    this.adjustLocation = false;
                }
            }
        });
    }

    async startChat(workOrderId = null) {

        if (this.chatFunction) {
            const args = new ExecuteFunctionArgs();
            args.functionJson = this.chatFunction.functionJson;
            args.name = '';// addOn.name;
            args.objectInScope = {};
            args.fixedSize = true;
            args.iconClass = this.chatFunction.iconClass;
            args.description = this.chatFunction.description;
            args.name = this.chatFunction.name;

            if (UtilitiesService.isIOS) {
                args.height = 'calc(100vh - 211px)';
            } else {
                args.height = 'calc(100vh - 100px)';
            }
            this.missionService.raiseExecuteFunction(args);
            return;
        }

        if (!this.chatConfig) {
            this.chatConfig = await this.amazonContactApi.getChatConfig();
        }

        this.showChat = true;
        this.connecting = true;
        const config =
            setTimeout(async () => {
                let user = await this.entityApi.getLoggedInUser(false);

                if (!user) {
                    user = new Entity();
                    user.id = UtilitiesService.newid();
                    user.name = 'Anonymous';
                }
                let username = `${user.id}_${UtilitiesService.newid()}`;
                if (workOrderId) {
                    username += `_workOrderId_${workOrderId}`;
                }

                // const apiId = 'sg0u1cx8ab';
                // const region = 'us-west-2';
                const url = `https://${this.chatConfig.amazonConnectApiId}.execute-api.${this.chatConfig.amazonConnectRegion}.amazonaws.com/Prod`;

                const contactAttributes = {
                    customerId: user.id,
                    customerNameTest: user.name,
                    name: user.name,
                };
                // const contactAttributesText = JSON.stringify({

                // }); 


                connect.ChatInterface.initiateChat({
                    name: user.name,
                    username,
                    customerId: user.id,
                    region: this.chatConfig.amazonConnectRegion,
                    apiGatewayEndpoint: url,
                    contactAttributes,
                    contactFlowId: this.chatConfig.amazonConnectContactFlowId, // "2b498b7f-75a3-447c-8afa-6046cee328ee",
                    instanceId: this.chatConfig.amazonConnectInstanceId, // "56a34e5f-8e7d-42f8-ba87-45e3e6693abe"
                }, chatSession => {
                    this.zone.run(() => {
                        this.connecting = false;
                    });

                    chatSession.onChatDisconnected(data => {
                        this.zone.run(() => {
                            this.showChat = false;
                            this.hideChatButton = false;
                        });
                    });
                    this.hideChatButton = true;
                }, error => {
                    console.error('error');
                    console.error(error);
                    this.zone.run(() => {
                        this.connecting = false;
                    });
                });
            });
    }
}
